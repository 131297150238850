import * as React from "react"
import { Helmet } from "react-helmet"

export default function Play() {
  return <>
      <Helmet>
          <title>Dragon Drummer</title>
          <link rel="canonical" href="https://dragondrummer.com/play" />
      </Helmet>
  </>
}
